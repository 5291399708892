import { CLINIC } from "../actions/actionTypes";

export const defaultProfileState = {
  clinic: {},
  dentists: [],
  services: [],
  insurances: [],
  isLoading: false,
  clinicLoading: false,
  dentistLoading: false,
  serviceLoading: false,
  insuranceLoading: false
};

export const clinic = (state = defaultProfileState, action) => {
  switch (action.type) {
    case CLINIC.PROFILE.start:
      return { ...state, isLoading: true };
    case CLINIC.PROFILE.success:
      return {
        ...state,
        isLoading: false,
        clinic: action.data,
        dentists: action.data.dentists,
        services: action.data.services,
        insurances: action.data.insurance_companies
      };
    case CLINIC.PROFILE.fail:
      return { ...state, isLoading: false };

    // Business info
    case CLINIC.BUSINESS_UPDATE.start:
      return { ...state, clinicLoading: true };
    case CLINIC.BUSINESS_UPDATE.success:
      return {
        ...state,
        clinicLoading: false,
        clinic: action.data
      };
    case CLINIC.BUSINESS_UPDATE.fail:
      return { ...state, clinicLoading: false };
    // Dentists
    case CLINIC.DENTISTS.start:
      return { ...state, dentistLoading: true };
    case CLINIC.DENTISTS.success:
      return {
        ...state,
        dentistLoading: false,
        dentists: action.data.dentists
      };
    case CLINIC.DENTISTS.fail:
      return { ...state, dentistLoading: false };

    // Services
    case CLINIC.SERVICES.start:
      return { ...state, serviceLoading: true };
    case CLINIC.SERVICES.success:
      return {
        ...state,
        serviceLoading: false,
        services: action.data.services
      };
    case CLINIC.SERVICES.fail:
      return { ...state, serviceLoading: false };

    // Insurances
    case CLINIC.INSURANCE.start:
      return { ...state, insuranceLoading: true };
    case CLINIC.INSURANCE.success:
      return {
        ...state,
        insuranceLoading: false,
        insurances: action.data.insurance_companies
      };
    case CLINIC.INSURANCE.fail:
      return { ...state, insuranceLoading: false };
    default:
      return state;
  }
};
