import axios from "axios";

export default () => {
  axios.interceptors.response.use(
    response => {
      if (response.status === 401) {
        // if user is not authenticated, clear user info from localStorage and reload app
        window.localStorage.clear();
        window.location.reload();
      }
      return response;
    },
    error => {
      return Promise.reject(error);
    }
  );
};
