import { RESERVATION } from "../actions/actionTypes";

export const defaultReservationsState = {
  isLoading: false,
  reservations: null
};

export const reservations = (state = defaultReservationsState, action) => {
  switch (action.type) {
    case RESERVATION.start:
      return { ...state, isLoading: true };
    case RESERVATION.success:
      return { ...action.data, isLoading: false };
    case RESERVATION.fail:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};
