import { CLINIC } from '../actions/actionTypes';

export const defaultScheduleState = {
  schedules: [],
  isLoading: false,
};

export const schedule = (state = defaultScheduleState, action) => {
  switch (action.type) {
    // Working Times
    case CLINIC.SCHEDULES.start:
      return { ...state, isLoading: true };
    case CLINIC.SCHEDULES.success:
      return { ...state, isLoading: false, schedules: action.data };
    case CLINIC.SCHEDULES.fail:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};
