export const attachStatusSuffix = actionType => ({
  start: `${actionType}_PENDING`,
  success: `${actionType}_FULFILLED`,
  fail: `${actionType}_REJECTED`
});

export const AUTH = {
  SIGNUP: attachStatusSuffix("SIGNUP"),
  LOGIN: attachStatusSuffix("LOGIN"),
  LOGOUT: attachStatusSuffix("LOGOUT"),
  DENTIST: attachStatusSuffix("LOGOUT")
};

export const CLINIC = {
  PROFILE: attachStatusSuffix("PROFILE"),
  BUSINESS_UPDATE: attachStatusSuffix("BUSINESS_UPDATE"),
  DENTISTS: attachStatusSuffix("DENTISTS"),
  SERVICES: attachStatusSuffix("SERVICES"),
  SCHEDULES: attachStatusSuffix("SCHEDULES"),
  INSURANCE: attachStatusSuffix("INSURANCE")
};

export const RESERVATION = attachStatusSuffix("RESERVATION");
export const PATIENT = attachStatusSuffix("PATIENT");
