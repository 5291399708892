import { createStore, applyMiddleware, combineReducers } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunkMiddleware from "redux-thunk";
import { auth, defaultAuthState } from "../reducers/auth";
import { clinic, defaultProfileState } from "../reducers/clinic";
import { schedule, defaultScheduleState } from "../reducers/schedules";
import {
  reservations,
  defaultReservationsState
} from "../reducers/reservations";
import { patients, defaultPatientsState } from "../reducers/patients";

const rootReducer = combineReducers({
  auth,
  clinic,
  schedule,
  reservations,
  patients
});

const defaultStore = {
  defaultAuthState,
  defaultProfileState,
  defaultScheduleState,
  defaultReservationsState,
  defaultPatientsState
};

const store = createStore(
  rootReducer,
  {},
  // composer(applyMiddleware(...middleWares)),
  composeWithDevTools(applyMiddleware(thunkMiddleware))
);

export default store;
