//
// ─── ENV CHECKS ─────────────────────────────────────────────────────────────────
//

const PORT = process.env.PORT || 3000;
const DEV_MODE = process.env.NODE_ENV === 'development';
const BUILD_DOMAIN = process.env.REACT_APP_DOMAIN
  ? process.env.REACT_APP_DOMAIN
  : `smile.idearun.co`;
const BUILD_API_KEY = process.env.REACT_APP_API_KEY
  ? process.env.REACT_APP_API_KEY
  : `2bc230f6eee5cf073adbfac0e6a40400268dee58`;

module.exports.DEV_MODE = DEV_MODE;
module.exports.BUILD_DOMAIN = BUILD_DOMAIN;
module.exports.BUILD_API_KEY = BUILD_API_KEY;

//
// ─── URLS AND DOMAIN SETTINGS ───────────────────────────────────────────────────
//

const BUILD_BASE_URL = `https://${BUILD_DOMAIN}`;
const BUILD_API_URL = `https://${BUILD_DOMAIN}`;

const baseUrl = DEV_MODE ? `http://localhost:${PORT}` : BUILD_BASE_URL;

const APIURL = BUILD_API_URL;
const APIKEY = BUILD_API_KEY;

module.exports.baseUrl = baseUrl;

module.exports.APIURL = APIURL;
module.exports.APIKEY = APIKEY;

//
// ─── LOCAL STORAGE ──────────────────────────────────────────────────────────────
//

const localStorageKey = 'smile-standard-auth';
const googleMapApiKey = 'AIzaSyBelG497OlQyFVUbSSepWnRgBa3Jv_V6KM';

module.exports.localStorageKey = localStorageKey;
module.exports.googleMapApiKey = googleMapApiKey;
