import React, { Component } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { toast } from 'react-toastify';
import panelRoutes from './routes/panelRoutes';
import 'react-toastify/dist/ReactToastify.css';
import './style/styles.css';

toast.configure();
class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>{panelRoutes}</Switch>
      </BrowserRouter>
    );
  }
}

export default App;
