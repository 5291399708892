import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isAuthenticated } from '../state/actions/authActions';

const RedirectRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() ? (
        <Redirect
          to={{
            pathname: '/',
            state: { from: props.location },
          }}
          {...props}
        />
      ) : (
        <Component {...props} />
      )
    }
  />
);

export default RedirectRoute;
