import { localStorageKey } from "../../settings";
import * as actionTypes from "./actionTypes";

export const isAuthenticated = () => {
  return (
    localStorage[localStorageKey] !== undefined &&
    Object.keys(localStorage[localStorageKey]).length
  );
};

export const setClinicData = data => async dispatch => {
  dispatch({
    type: actionTypes.AUTH.LOGIN.success,
    data
  });
  localStorage.setItem(localStorageKey, JSON.stringify(data));
};

export const removeAuthData = () => {
  localStorage.removeItem(localStorageKey);
  window.location.replace("/");
};
