import { AUTH } from '../actions/actionTypes';

export const defaultAuthState = {
  dentist: {},
  isLoading: false,
};

export const auth = (state = defaultAuthState, action) => {
  switch (action.type) {
    case AUTH.SIGNUP.start:
    case AUTH.LOGIN.start:
    case AUTH.DENTIST.start:
      return { isLoading: true };
    case AUTH.SIGNUP.success:
      return { isLoading: false };
    case AUTH.LOGIN.success:
    case AUTH.DENTIST.success:
      return { isLoading: false, dentist: action.data }
    case AUTH.SIGNUP.fail:
    case AUTH.LOGIN.fail:
    case AUTH.DENTIST.fail:
      return { isLoading: false };
    default:
      return state;
  }
};
