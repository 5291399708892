import React from "react";
import { Route } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import RedirectRoute from "./RedirectRoute";
import { Home, Dashboard, Account, NotFound } from "./Routes";

const routeTypes = {
  authenticated: PrivateRoute,
  notAuthenticated: RedirectRoute,
  public: Route
};

const allRoutes = [
  {
    path: ["/", "/dashboard"],
    type: "authenticated",
    component: Home,
    exact: true
  },
  {
    path: "/dashboard/:dashboardLink",
    type: "authenticated",
    component: Dashboard
  },
  {
    path: "/account",
    type: "notAuthenticated",
    component: Account
  },
  {
    type: "public",
    component: NotFound
  }
];

const panelRoutes = allRoutes.map((route, index) => {
  const RouteWrapper = routeTypes[route.type];
  return <RouteWrapper {...route} key={index} />;
});

export default panelRoutes;
