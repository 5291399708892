import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import {isAuthenticated} from '../state/actions/authActions';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
      {...rest}
    render={(props) => (isAuthenticated() ? <Component {...props} /> : <Redirect to="/account/login" />)}
  />
);

export default PrivateRoute;
