import AsyncRoute from './AsyncRoute';

//
// Authenticated Routes ───────────────────────────────────────────────────
//

export const Dashboard = AsyncRoute(() => import('../components/Pages/Dashboard/Dashboard'))


//
// Not Authenticated Routes ───────────────────────────────────────────────────
//

export const Account = AsyncRoute(() => import('../components/Pages/Account/Account'))


//
//Public Routes ───────────────────────────────────────────────────
//

export const Home = AsyncRoute(() => import('../components/Pages/Dashboard/Home'))

export const NotFound = AsyncRoute(()=> import('../components/Pages/NotFound/NotFound'))
