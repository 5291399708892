import { red } from "@material-ui/core/colors";
import { createMuiTheme } from "@material-ui/core/styles";
import InriaSans from "../assets/font/InriaSans-Regular.woff";

const font = {
  fontFamily: "InriaSans",
  fontStyle: "normal",
  fontDisplay: "swap",
  // fontWeight: 100,
  src: `
      url(${InriaSans}) format('woff')`
};

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#01B1C9",
      dark: "#017180"
    },
    secondary: {
      main: "#19857b"
    },
    error: {
      main: red.A400
    },
    background: {
      default: "#F0F0F0"
    },
    common: {
      dark: "#434e52",
      gray: "#CECECE",
      lightGray: "#F6F6F6"
    }
  },
  typography: {
    fontFamily: ["InriaSans"].join(","),
    fontSize: 14
    // Tell Material-UI what's the font-size on the html element is.
    // htmlFontSize: 16,
  }
});

const extendedTheme = theme =>
  createMuiTheme({
    ...theme,
    overrides: {
      MuiCssBaseline: {
        "@global": {
          "@font-face": [font]
        }
      },
      MuiTypography: {
        h6: {
          fontWeight: 600
        }
      },
      MuiButton: {
        root: {
          textTransform: "capitalize",
          minHeight: 40,
          fontSize: "1.3em"
        },
        contained: {
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none"
          }
        },
        containedPrimary: {
          color: "white"
        }
      },
      MuiFormControl: {
        root: {
          width: "100%"
        }
      },
      MuiInput: {
        underline: {
          "&:before": {
            borderBottom: "2px solid rgba(204,204,204,0.5)"
          },
          "&:hover:not(.Mui-disabled):before": {
            borderColor: theme.palette.primary.main
          }
        }
      },
      MuiLink: {
        underlineHover: {
          "&:hover": {
            textDecoration: "none"
          }
        }
      },
      MuiPaper: {
        elevation1: {
          boxShadow: "none"
        },
        rounded: {
          borderRadius: 10
        }
      },
      MuiPickersToolbarButton: {
        toolbarBtn: {
          height: "100%"
        }
      },
      MuiPickersToolbarText: {
        toolbarBtnSelected: {
          color: "white"
        },
        toolbarTxt: {
          color: "white"
        }
      }
    }
  });

export default { theme, extendedTheme };
// export default extendedTheme;
